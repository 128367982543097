.emblem_banner {
  overflow: hidden;
  padding-top: 0.5em;
  border-top: 1px solid #d4d4d4;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #d4d4d4;
  background-color: #f8f8f8;
}

.emblem { 
  width: 25%;
  float: left;
  transition: transform 0.5s;

  &.highlight {
    transform: scale(1.02);
    transition: transform 0.5s;
  }
}

.emblems { 
  margin-right: auto;
  margin-left: auto;
  width: 100%; 
  opacity: 0;
  overflow: hidden;

  &.fade_in { 
    opacity: 1;
    transition: opacity 3s;
  }
}

// The 'posts' page needs some extra attention to get the
// width of the miniature right and responsive, as it misses
// the 'page' container.
//
// normal pages, page 'posts'
.page .miniature, #main > .miniature {
  width: 75%;
}
// page 'posts'
@media (min-width: 50em) {
  #main > .miniature {
    width: calc((100% - 200px)*0.75);
  }
}
@media (min-width: 75em) {
  #main > .miniature {
    width: calc((100% - 500px)*0.75);
  }
}

.miniature {
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow: hidden;
  opacity: 0;

  &.fade_in { 
    opacity: 1; 
    transition: opacity 3s;
  }

  img {
    background-color: #f8f8f8;
    padding: 0.4em; 
    border: 1px solid #d4d4d4;
  }

}


.button-share {
  background-color: transparent;
  border: 1px solid #5a5a5a;
  border-color: #d4d3d3;
  /* color: #adabac; */
  border-radius: 50%;
  /* color: #d4d3d3; */
  display: inline-block;
  font-size: 16px;
  height: 48px;
  line-height: 2.85714;
  position: relative;
  text-align: center;
  width: 48px;
  margin-right: 15px;
}

.button-share::after {
  box-shadow: 0 0 0 1px rgba(173,171,172,0.45);
}

.button-share::after {
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(90,90,90,0.3);
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  height: 100%;
  left: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.2s, opacity 0.4s;
  -moz-transition: -moz-transform 0.2s, opacity 0.4s;
  transition: transform 0.2s, opacity 0.4s;
  width: 100%;
}

.button-share:hover::after {
  left: -7px;
  opacity: 0;
  padding: 7px;
  top: -7px;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

.icon-social {
  font-family: 'Font Awesome 5 Brands';
  color: #adabac;
}

.icon-social.rss {
  font-family: 'Font Awesome 5 Free';
  color: #adabac;
}

.page__footer-follow .icon-social {
  color: rgb(255, 254, 253);
}

.button-share:hover .icon-social {
  color: #5a5a5a;
}

.page__footer-follow .button-share:hover .icon-social {
  color: rgba(243, 188, 121, 0.911)
}

h3#references + ul {
  font-size: 0.85em;
  line-height: 90%;
  list-style: none;
  padding-left: 0px;

  li {
    margin-bottom: 0.5em;
  }
}

.badge_thumb_container{
  display: inline-block;
  margin-right: 0.5em;
  margin-bottom: 1.5em;
}

.page__content p img {
  padding: 1em;
  border: 1px solid #f2f3f3;
  margin-top: 1em;
  margin-bottom: 0.5em;
}